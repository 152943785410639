import React, { useState } from "react";
import axios from "axios";

const Contact = () => {
  const [sentEmail, setSentEmail] = useState(false);

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    msg: ""
  });

  const updateFormValues = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    console.log(form);
    axios
      .post("https://staging.ebricknetwork.com/mail/mail.php", { form })
      .then(res => {
        console.log("res:", res);
        console.log("res.data:", res.statusText);
        if (res.statusText === "OK") {
          setForm({
            name: "",
            email: "",
            phone: "",
            msg: ""
          });
        }

        setSentEmail(true);
      });
  };

  return (
    <div
      className="w-full mx-auto pt-20 pb-10"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right"
      }}
      id="contacto"
    >
      <div className="mx-auto text-center my-4">
        {" "}
        <img
          src="images/eln-logo-white.png"
          alt="eBrick Network"
          className="text-center mx-auto"
          style={{ maxWidth: "300px" }}
        />
      </div>

      <div className="w-full lg:w-10/12 mx-auto lg:flex lg:pt-20">
        <div className="w-full lg:w-1/3 bg-white px-10 py-5">
          <h3 className="text-blue-800 text-center">Contáctenos</h3>
          <form className="my-10" onSubmit={handleSubmit}>
            <input
              value={form.name}
              onChange={updateFormValues}
              type="text"
              name="name"
              placeholder="Nombre*"
              className="w-full mx-auto px-4 py-3 bg-gray-300 text-gray-700 my-5"
            />

            <input
              value={form.email}
              onChange={updateFormValues}
              type="email"
              name="email"
              placeholder="Email*"
              className="w-full mx-auto px-4 py-3 bg-gray-300 text-gray-700 my-5"
            />

            <input
              value={form.phone}
              onChange={updateFormValues}
              type="text"
              name="phone"
              placeholder="Teléfono"
              className="w-full mx-auto px-4 py-3 bg-gray-300 text-gray-700 my-5"
            />

            <textarea
              value={form.msg}
              onChange={updateFormValues}
              name="msg"
              className="w-full mx-auto px-4 py-3 bg-gray-300 text-gray-700 my-5"
              placeholder="Mensaje"
            ></textarea>

            <button className="w-full mx-auto px-4 py-3 bg-teal-500 text-gray-100 my-5 text-sm">
              Enviar Mensaje
            </button>
          </form>
        </div>
        <div
          className={`${
            sentEmail ? `block` : `hidden`
          } w-full lg:w-1/3 bg-white px-10 py-5 `}
        >
          <h3 className="text-blue-800 text-center">
            ¡Gracias por su mensaje!
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Contact;
